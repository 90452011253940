import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponNames } from '@wix/loyalty-coupon-names';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createCurrencyFormatter } from '../createCurrencyFormatter';

interface CreateRewardDescriptionParams {
  reward: Reward;
  loyaltyProgram: LoyaltyProgram;
  flowAPI: ControllerFlowAPI;
  configIndex: number;
  couponNames: CouponNames;
}

export const createRewardDescription = ({
  reward,
  loyaltyProgram,
  flowAPI,
  configIndex,
  couponNames,
}: CreateRewardDescriptionParams) => {
  let discountLabel: string | undefined;
  let costInPoints: number | undefined;

  const { translations } = flowAPI;
  const { t } = translations;
  const formatCurrency = createCurrencyFormatter(flowAPI);
  const getCouponSubtitle = couponNames.getCouponSubtitle;

  if (reward.type === RewardType.DISCOUNT_AMOUNT) {
    const config = reward.discountAmount?.configsByTier?.[configIndex]!;
    const amount = parseFloat(config.amount ?? '0');

    costInPoints = config.costInPoints;
    discountLabel = t('app.reward-description.discount-amount', {
      discountAmount: formatCurrency(amount),
    });
  } else if (reward.type === RewardType.COUPON_REWARD) {
    const couponReward = reward.couponReward!;
    const configsByTier =
      couponReward.percentage?.configsByTier ??
      couponReward.fixedAmount?.configsByTier ??
      couponReward.freeShipping?.configsByTier;

    const config = configsByTier?.[configIndex]!;

    costInPoints = config.costInPoints;
    discountLabel = getCouponSubtitle({ couponReward: couponReward!, config });
  } else {
    discountLabel = t('app.reward-description.empty');
  }

  if (costInPoints === undefined || discountLabel === undefined) {
    return '';
  }

  const pointsKey = loyaltyProgram.pointDefinition?.customName
    ? 'app.reward-description.points-custom'
    : 'app.reward-description.points';

  const pointsLabel = t(pointsKey, {
    count: costInPoints,
    pointsName: loyaltyProgram.pointDefinition?.customName,
  });

  return `${pointsLabel} = ${discountLabel}`;
};
